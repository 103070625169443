import { h, render, Fragment } from "preact";

export default function About() {
  return (
    <div className="bg-white font-merri h-[100vh]">
      {/* Header */}
      <div className="relative bg-[#0d1b25] pb-32">
        <div className="absolute inset-0 bg-[#0d1b25]" />
        <div className="flex flex-col justify-center items-center relative mx-auto max-w-7xl px-6 py-24 sm:pt-[8rem] sm:pt-[10rem] lg:px-8" data-aos="fade-up" data-aos-duration="800" data-aos-easing="linear">
          <h1 className="text-4xl font-bold tracking-tight text-white md:text-5xl lg:text-6xl">About Bison Cash</h1>
          <p className="mt-6 max-w-3xl text-xl text-gray-300 text-justify">
            Discover financial stability with Bison Cash, your trusted partner in flexible installment loans. We're committed to empowering individuals through transparent, personalized service and competitive rates.
          </p>
          <p className="mt-6 max-w-3xl text-xl text-gray-300 text-justify">With a streamlined application process and convenient repayment options, Bison Cash provides quick access to funds for life's unexpected expenses. Experience the Bison Cash difference and take control of your financial future today.</p>
        </div>
      </div>
      {/* Overlapping cards */}
      <section className="relative z-10 mx-auto -mt-32 max-w-7xl px-6 pb-32 lg:px-8" aria-labelledby="contact-heading">
        <div className="grid grid-cols-1 gap-y-4 md:gap-y-20 lg:grid-cols-3 lg:gap-x-8 lg:gap-y-0">
          <div className="flex flex-col rounded-2xl bg-white shadow-xl" data-aos="fade-up" data-aos-anchor-placement="center-bottom" data-aos-duration="800" data-aos-easing="linear">
            <div className="relative flex-1 px-6 pt-[2rem] md:px-8">
              <h3 className="text-xl font-semibold text-[#4a4a4a]">Quick Application</h3>
              <p className="mt-4 text-base text-[#4a4a4a]">Our online application is{' '}
                <span className="text-green-500">
                  fast and easy
                </span>{' '}
                to complete. It's quite literally a
                click away!
              </p>
              <div className="flex justify-center items-center">
                <img
                  className="h-[auto] md:h-[15em] w-22 inline-flex items-center justify-center"
                  src="/public/src/img/bsc_loan_1.jpg"
                  alt="Quick Application"
                />
              </div>
            </div>
            <div className="flex justify-center items-center rounded-bl-2xl rounded-br-2xl bg-white p-6 md:px-8">
              <a href="/apply-now" className="transition-colors duration-300 ease-in-out text-white bg-portmain-500 hover:bg-portmain-600 no-underline font-inter text-center px-[3rem] py-[0.75rem] rounded-md shadow-sm text-lg font-medium" style="box-shadow: rgb(60 174 90) 0px 10px 40px -5px;">
                Get Started
              </a>
            </div>
          </div>
          <div className="flex flex-col rounded-2xl bg-white shadow-xl" data-aos="fade-up" data-aos-anchor-placement="center-bottom" data-aos-duration="900" data-aos-easing="linear">
            <div className="relative flex-1 px-6 pt-[2rem] md:px-8">
              <h3 className="text-xl font-semibold text-[#4a4a4a]">Safe Payments</h3>
              <p className="mt-4 text-base text-[#4a4a4a]">We make sure to keep your information{' '}
                <span className="text-green-500">
                  protected.
                </span>
              </p>
              <div className="flex justify-center items-center">
                <img
                  className="h-[auto] md:h-[15em] w-22 inline-flex items-center justify-center"
                  src="/public/src/img/bsc_secure_1.jpg"
                  alt="Safe Payments"
                />
              </div>
            </div>
            <div className="flex justify-center items-center rounded-bl-2xl rounded-br-2xl bg-white p-6 md:px-8">
              <a href="/apply-now" className="transition-colors duration-300 ease-in-out text-white bg-portmain-500 hover:bg-portmain-600 no-underline font-inter text-center px-[3rem] py-[0.75rem] rounded-md shadow-sm text-lg font-medium" style="box-shadow: rgb(60 174 90) 0px 10px 40px -5px;">
                Get Started
              </a>
            </div>
          </div>
          <div className="flex flex-col rounded-2xl bg-white shadow-xl" data-aos="fade-up" data-aos-anchor-placement="center-bottom" data-aos-duration="1000" data-aos-easing="linear">
            <div className="relative flex-1 px-6 pt-[2rem] md:px-8">
              <h3 className="text-xl font-semibold text-[#4a4a4a]">Customer Care</h3>
              <p className="mt-4 text-base text-[#4a4a4a]">Our{' '}
                <span className="text-green-500">
                  friendly and welcoming
                </span>{' '}
                Customer Service Representatives are
                here for you.
              </p>
              <div className="flex justify-center items-center">
                <img
                  className="h-[auto] md:h-[15em] w-22 inline-flex items-center justify-center"
                  src="/public/src/img/bsc_support_1.jpg"
                  alt="Support"
                />
              </div>
            </div>
            <div className="flex justify-center items-center rounded-bl-2xl rounded-br-2xl bg-white p-6 md:px-8">
              <a href="/apply-now" className="transition-colors duration-300 ease-in-out text-white bg-portmain-500 hover:bg-portmain-600 no-underline font-inter text-center px-[3rem] py-[0.75rem] rounded-md shadow-sm text-lg font-medium" style="box-shadow: rgb(60 174 90) 0px 10px 40px -5px;">
                Get Started
              </a>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
