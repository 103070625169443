import { h } from 'preact';
import useAuth from 'js/components/User/useAuth.js';
import { Flowbite, Navbar } from 'flowbite-react';
import flowbiteTheme from '../../themes/flowbite_theme';
import logo from 'img/Bison Cash Logo.png';

const navigation = [
    { name: 'Sign In', href: '/login' },
    { name: 'Apply Now', href: '/apply-now' },
    { name: 'Contact', href: '/contact-us' },
];

const navigation_authenticated = [
    { name: 'My Account', href: '/account/#' },
    { name: 'Loan Overview', href: '/account/#loan' },
    { name: 'Payment Options', href: '/account/#payment' },
    { name: 'My Profile', href: '/account/#profile' },
    { name: 'Loan History', href: '/account/#history' },
    { name: 'Contact', href: '/contact-us' },
];

export default function Header() {
    const auth = useAuth();

    return (
        <Flowbite theme={{ mode: 'light', theme: flowbiteTheme }}>
            <div className="bg-[#6b7280] sm:font-inter py-4">
                <Navbar className="bg-[#6b7280] mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
                    <Navbar.Toggle />
                    <Navbar.Brand href="/">
                        <img
                            src={logo}
                            className="h-14 w-auto"
                            alt="Bison Cash Logo"
                        />
                    </Navbar.Brand>
                    <div
                    className="md:hidden w-auto"
                    >
                        &nbsp;
                    </div>
                    {(auth.loading || !auth.user) && (
                        <Navbar.Collapse>
                            {navigation.map((item) => (
                                <Navbar.Link
                                    key={item.name}
                                    href={item.href}
                                >
                                    {item.name}
                                </Navbar.Link>
                            ))}
                        </Navbar.Collapse>
                    )}
                    {!auth.loading && auth.user && (
                        <Navbar.Collapse>
                            {navigation_authenticated.map((item) => (
                                <Navbar.Link
                                    key={item.name}
                                    href={item.href}
                                >
                                    {item.name}
                                </Navbar.Link>
                            ))}
                            <Navbar.Link
                                href="#"
                                onClick={() => {
                                    auth.sign_out(() => {
                                        window.location = '/';
                                    });
                                }}

                            >
                                Log Out
                            </Navbar.Link>
                        </Navbar.Collapse>
                    )}
                </Navbar>
            </div>
        </Flowbite>
    );
}
