import { h, render, Fragment } from "preact";

export default function CTA() {
  return (
    <div className="relative bg-gray-50 font-merri">
      <div className="mx-auto text-center max-w-md py-20 px-4 sm:max-w-3xl sm:px-6 lg:max-w-7xl lg:px-8">
        <div data-aos="fade-up" data-aos-duration="800" data-aos-easing="linear">
          <h2 className="text-4xl font-semibold tracking-wider text-[#4a4a4a]">
            Ready to start?
          </h2>
          <p className="mt-5 text-lg text-[#4a4a4a]">
            With a click of a button, Bison Cash is <span className="text-green-500">committed</span> to keeping you <span className="text-green-500">financially covered</span>.
          </p>
        </div>
        <div>
          <div className="mt-[2rem] mb-[3rem] flex justify-center items-center">
            <img className="h-[auto] md:h-[20rem]" src="/public/src/img/bsc_cta_1.jpg" data-aos="fade-up" data-aos-duration="800" data-aos-anchor-placement="center-bottom" data-aos-easing="linear" />
          </div>
          <a
            href="/apply-now"
            className="transition-colors duration-300 ease-in-out text-white bg-portmain-500 hover:bg-portmain-600 no-underline font-inter text-center px-[3rem] py-[1rem] rounded-md shadow-sm text-lg font-medium"
            style="box-shadow: rgb(60 174 90) 0px 10px 40px -5px;"
            data-aos="fade-up" data-aos-duration="800" data-aos-anchor-placement="center-bottom" data-aos-easing="linear"
          >
            Get Started
          </a>
        </div>
      </div>
    </div>
  );
}
