import { h, render, Fragment } from "preact";
import { useEffect } from "preact/hooks";
import "css/pages/hero.css";
import Aos from "aos";
import "aos/dist/aos.css";

export default function Hero() {
    useEffect(() => {
        Aos.init({ delay: 200, once: false });
    }, []);

    return (
        <div
            id="hero"
            className="flex justify-center items-start relative w-full h-[90vh] bg-gray-500 bg-blend-multiply sm:bg-no-repeat sm:bg-center lg:bg-cover lg:bg-blend-normal lg:bg-center"
        >
            <div className="relative w-full h-64 hidden sm:h-72 lg:block lg:h-96 lg:absolute lg:w-full lg:h-full">
                <div
                    className="absolute inset-0 bg-gray-500 mix-blend-multiply"
                    aria-hidden="true"
                />
            </div>
            <main className="lg:relative font-merri" data-aos="fade-in" data-aos-duration="1000" data-aos-easing="linear">
                <div className="flex justify-center items-center mx-auto max-w-7xl w-full pt-16 pb-10 mt-[5rem] text-center lg:pt-35 lg:pb-33">
                    <div className="flex flex-col justify-center items-center">
                        <h1 className="text-4xl text-center tracking-tight font-extrabold text-white sm:text-5xl md:text-6xl lg:text-5xl xl:text-6xl">
                            Let's Conquer Your Financial Frontier
                        </h1>
                        <p className="mt-8 max-w-md mx-auto text-lg text-center text-gray-300 sm:text-xl md:mt-5 md:max-w-3xl">
                            Apply instantly. Affordable rates. Financial
                            freedom.
                        </p>
                        <a
                            href="/apply-now"
                            className="transition-colors duration-300 ease-in-out text-white bg-portmain-500 hover:bg-portmain-600 mt-24 no-underline font-inter text-center px-[3rem] py-[0.75rem] rounded-md shadow-sm text-lg font-medium"
                            style="box-shadow: rgb(60 174 90) 0px 10px 40px -5px;"
                        >
                            Apply Now
                        </a>
                    </div>
                </div>
            </main>
        </div>
    );
}
